import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import './App.css';

function App() {
  const [scrollY, setScrollY] = useState(0);
  const [titleOpacity, setTitleOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      setScrollY(newScrollY);
      
      const newOpacity = Math.max(0, 1 - (newScrollY / 200));
      setTitleOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Initialize GA4 with your measurement ID
    ReactGA.initialize('G-F52SZ503JL'); // Replace with your GA4 measurement ID
    
    // Send initial pageview
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const movementFactor = 0.5;
  const backgroundY = -scrollY * movementFactor;

  const containerStyle = {
    background: `url('/background.png') no-repeat center`,
    backgroundSize: 'cover',
    backgroundPosition: `center ${backgroundY}px`,
    width: '100%',
    height: '200vh',
    position: 'relative'
  };

  const headerStyle = {
    position: 'fixed',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: titleOpacity,
    transition: 'opacity 0.3s ease-out',
    color: 'white',
    zIndex: 3,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  };

  const footerStyle = {
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    gap: '30px',
    zIndex: 3
  };

  const socialIconStyle = {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
    filter: 'brightness(0) invert(1)'  // Makes icons white
  };

  // Add click tracking to social links
  const handleSocialClick = (platform) => {
    ReactGA.event({
      category: 'Social',
      action: 'Click',
      label: platform
    });
  };

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <main style={{ flex: 1 }}>
        <div style={containerStyle}>
          {titleOpacity > 0 && (
            <div style={headerStyle}>
              <img src="/kaspalogo.png" alt="Kaspa Logo" style={{ width: '50px', height: 'auto' }} />
              <h1 style={{ fontSize: '2.5rem', margin: 0, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                Kaspa Yellow-Submarine Committee
              </h1>
            </div>
          )}
          <div className="submarine-container">
            <img src="/submarine.png" alt="Submarine" className="submarine" />
          </div>
          
          <div style={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: '60px',
            zIndex: 3
          }}>
            <a 
              href="https://discord.gg/kaspa" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleSocialClick('Discord')}
            >
              <img src="/discord.svg" alt="Discord" style={socialIconStyle} />
            </a>
            <a 
              href="https://twitter.com/kaspakyc" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleSocialClick('Twitter')}
            >
              <img src="/twitter.svg" alt="Twitter" style={socialIconStyle} />
            </a>
            <a 
              href="https://reddit.com/r/kaspa" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleSocialClick('Reddit')}
            >
              <img src="/reddit.svg" alt="Reddit" style={socialIconStyle} />
            </a>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <div style={{
        width: '100%',
        textAlign: 'center',
        padding: '20px 0',
        fontSize: '14px',
        color: '#8B8B8B',
        background: '#1A1B1F',
        borderTop: '1px solid #2D2E32',
        marginTop: 'auto'
      }}>
        © {new Date().getFullYear()} Kaspa Yellow-Submarine Committee. All rights reserved. 
        <br/>
        <span style={{ fontSize: '12px', opacity: '0.8' }}>
          This is a community initiative and not an official Kaspa website.
        </span>
      </div>
    </footer>
  );
}

export default App;
